import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./AIReadinessAssessment.css"

function ScoreApp() {
  useEffect(function createStyle() {
    // Create a new style element
    const style = document.createElement("style")
    style.innerHTML = `
          .sa-full>iframe {
                padding-top: 3.5rem;
                position: relative !important;

                @media screen and (min-width: 640px) {
                    padding-top: 6rem;
                }
            }
        `

    // Append the style element to the document's head
    document.body.appendChild(style)

    return () => {
      // Cleanup: Remove the style element on unmount
      document.body.removeChild(style)
    }
  }, [])

  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://static.scoreapp.com/js/integration/v1/embedding.js?v=FAi31h"
    script.async = true

    const scoreAppContainer = document.getElementById("score-app")
    if (!scoreAppContainer) return

    // Append the script to the body
    scoreAppContainer.appendChild(script)

    // Cleanup function to remove the script on unmount
    return () => {
      scoreAppContainer.removeChild(script)
    }
  }, [])

  return (
    <div
      className="container"
      id="score-app"
      style={{
        minHeight: "calc(100vh - 400px)",
        position: "relative",
        flex: "1 0 auto",
        marginBottom: "4rem",
        zIndex: 1,
      }}
      data-sa-url="https://61d53b18-f804-466e-a8df-2b75a094cda2.scoreapp.com/?sa_hide_header=1&sa_hide_footer=1&sa_target=_top"
      data-sa-view="full"
    ></div>
  )
}

function AiReadinessAssessment(props) {
  const iframeRef = useRef(null)

  useEffect(() => {
    const iframe = iframeRef.current
    if (!iframe) return

    const handleLoad = () => {
      // Set a large initial height to ensure content is visible
      iframe.style.height = "280vh"

      // Use message event to get actual content height from iframe
      const handleMessage = event => {
        if (
          event.data &&
          typeof event.data === "object" &&
          event.data.type === "setHeight"
        ) {
          iframe.style.height = `${event.data.height}px`
        }
      }

      window.addEventListener("message", handleMessage)

      // Inject height calculation script into iframe
      try {
        iframe.contentWindow.postMessage(
          {
            type: "getHeight",
            script: `
            function sendHeight() {
              const height = document.documentElement.scrollHeight;
              window.parent.postMessage({ type: 'setHeight', height }, '*');
            }
            // Send height on load and on any content changes
            sendHeight();
            window.addEventListener('resize', sendHeight);
            const observer = new MutationObserver(sendHeight);
            observer.observe(document.body, { 
              childList: true, 
              subtree: true 
            });
          `,
          },
          "*"
        )
      } catch (error) {
        console.log("Failed to inject height calculation script:", error)
      }

      return () => window.removeEventListener("message", handleMessage)
    }

    iframe.addEventListener("load", handleLoad)

    return () => {
      iframe.removeEventListener("load", handleLoad)
    }
  }, [])

  return (
    <Layout location={props.location}>
      <SEO
        title="AI Readiness Assessment | Quandary Consulting Group"
        description="With personalized insights, you'll get a clearer picture of how AI fits into your business – and where your tech stack may need an upgrade."
      />

      <section className="relative flex-grow">
        <iframe
          className="score-app"
          ref={iframeRef}
          src="https://61d53b18-f804-466e-a8df-2b75a094cda2.scoreapp.com/?sa_hide_header=1&sa_hide_footer=1&sa_target=_top"
          width="100%"
          style={{
            border: "none",
            position: "relative",
            display: "block",
            width: "100%",
            paddingTop: "5rem",
            overflow: "hidden", // Prevent iframe scrolling
            transition: "height 0.3s ease",
          }}
          scrolling="no" // Disable iframe scrolling
          title="AI Readiness Assessment"
        />
      </section>
    </Layout>
  )
}

export default AiReadinessAssessment
